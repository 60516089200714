@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat');
.App {
  text-align: center;
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.whatsapp:hover{
  cursor: pointer;
}
.call:hover{
  cursor: pointer;
}

/*//////////////////////////////////////// testing slidrs ////////////////////////////////////*/

.text-slider{
  rotate: 180deg;
}
.text-slider-nest{
  rotate: 180deg;
}

/*//////////////////////////////////////// testing slidrs ////////////////////////////////////*/

.chat-frame{
  box-shadow: 1px 1px 30px #86241D;
  
}


  /*======================================================Navabar=========================================== */
  
  li {
    padding: 0px 20px;
    font-family: 'Poppins', sans-serif;
   
    color: #707070;
    cursor: pointer;
  }
  li:hover{
    color: #86241D;
    font-weight: 800;
  }
  .hover-bar-path{
    padding: 1.5px;
    width: 22px;
    border-radius: 10px;
    transition: all 0.5s;
    background-color: #86241D;
    
  }

  .hover-bar{
      padding: 1.5px;
      background-color: #86241D;
      width: 22px;
      transform: scale(0);
      border-radius: 10px;
    }
  .item-div:hover .hover-bar {
   transform: scale(1);
   transition: all .3s ease-in-out;
    
  }
  .nav-div {
    box-shadow: 3px 3px 20px rgb(198, 197, 197);
    position: fixed;
    width: 100%;
  }
  .nav-div-sm {
    position: fixed;
    width: 100%;
  }
  .nav-2{
    position: sticky !important;
  }
  .sticky {
    position: fixed !important;
    top: 10 !important;
    left: 0;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 0px; 
    transition: left 0.3s ease-in-out;
  }
.humb{
  color: #86241D;
}
.product-li{
  height: 200px;
  padding-top: 5%;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #707070;
  transition: all 0.3s ease-in-out;
  box-shadow: inset 1px 1px 10px #a2a2a2;
  border-radius: 3px;
}
.product-li-after {
  height: 0px !important;
  transition: all 1s ease-in-out;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #707070;

}
.menu{
 transform: scale(0);
  transition: all .3s ease-in-out;
}
.drop:hover .menu {
  transform: scale(1);
}
.chakra-modal__body{
  padding: 0% !important;
  margin: 0% !important;
}
.chakra-modal__body li{
  padding: 5% 0% !important;
  margin: 3% 0% !important;
  width: 320px;
}
  @media only screen and (max-width: 650px) {
    .nav-div-nest{
      font-size: xx-small;
       display: flex;
       justify-content: start;
    }
    .nav-div-nest li {
      padding: 15px 0px;
      font-size: 16px;
    }
    .nav-div-nest ul{
      padding: 0%;
      margin: 0%;
      display: flex;
      flex-direction: column;
    }
    .button-div{
      display: flex;
      justify-content: center;
    }
    .home-slider{
      width: 25rem;
      padding: 7% 7% 0% 7%;
    }
    .slides{
      width: 150px !important;
      font-size: 14px;
      padding: 10px !important;
    }
    h1{
      font-size: 24px ;
    }
    h2{
      font-size: 14px ;
    }
    p{
      font-size: 14px ;
    }
    .home-div{
      height: 500px;
    }
    .text-slider-nest{
     display: flex;
     flex-wrap: wrap;
     font-size: 12px;
     padding: 0px 2%;
    }
    .text-slider{
      height: 350px !important;
      margin-top: 2.5%;
    }
   

   
    .slider-img{
      height: 300px;
      width: 300px;
      padding: 0px 0px;
    }
    .shine-slider-div{
      display: flex;
      justify-content: center !important;
    }
    .installation-font{
      font-size: 14px;
      padding: 0px !important;
    }
    .installation-div{
      margin-top: -100px !important;
    }
    ul li {
      padding: 0px 3px;
    }
    /* .dis-img-bar {
      padding: 4px ;
      width: 35px ;
      border-radius: 10px;
      position: relative;
      left: 6px !important;
      background-color: #ffffff;
    } */
    .des-img-border {
      border: 2px solid #ffffff !important;
      border-radius: 5px;
    }
    
  }
  @media screen and (max-width:820px){
    ul li {
      padding: 0px 3px;
    }
    /* .dis-img-bar {
      padding: 4px ;
      width: 35px ;
      border-radius: 10px;
      position: relative;
      left: 15px;
      background-color: #ffffff;
    } */
    
  }
  @media screen and (max-width:1250px){
    ul li {
      padding: 0px 7px;
    }
    /* .dis-img-bar {
      padding: 2px;
      width: 22px;
      border-radius: 10px;
      background-color: #ffffff;
      margin-top: 15%;
      margin-left: 2px !important; 
      margin-right: 2px !important; 
    } */
    .home-tree{
      display: none !important;
    }
    /* .footer-input{
      width: 150px !important;
    } */
  }
  
  /* ===================================================Home================================================*/
  .marguee{
    background-color: #86241D;
    font-size: small;
  }
  /*.................................................home-main...............................................*/
  .font-w-400{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }
  .font-w-600{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
  .font-Montserrat{
    font-family: 'Montserrat', sans-serif;
  }
  .c-green{
    color: #87D900;
  }
  .c-gray{
    color: #707070;
  }
  .slides:hover,
  .slides:focus {
  box-shadow: inset 200px 0 0 0 #86241D;
}
 /* ......................................Review ...................................................*/

  .slide {
    position: absolute;
    top: 0;
    z-index: -1;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #86241D; /* Brown color */
    transition: left 0.3s ease-in-out;
  }
  
  .button:hover .slide {
    left: 0;
   
  }
  .slide-background{
    position: relative;
    overflow: hidden;
    display: 'inline-block';
    transition: box-shadow 0.5s ease;
    box-shadow: 10px 10px 19px -4px rgba(0,0,0,0.75);
    border-radius: 10px
  }
  
  .slide-background:hover {
    
    box-shadow: 0px 0px 0px -0px rgba(0,0,0,0.75);
  }

  .begining{
    background-image: url('./Images/Rectangle_6.png');
    
  }
  
  /* ......................................Symphony of Services ...................................................*/
  
  .card {
    max-width: 300px;
    border-radius: 20px;
    box-shadow: 6px 6px 2px #66A400;
    border: solid 1px #66A400;
    transform: scale(1);
    transition: transform 0.8s, box-shadow 0.8s;
  }
  
  .card:hover {
    box-shadow: 10px 10px 20px 10px #66A400;
    cursor: pointer;
    transform:  translate(-3px, -3px);
  }
  
  .logo.is-animetion{
    margin-top: 0.6em;
  }
  
  
  .logo.is-animetion span {
    display: inline-block;
    animation: wave-text 0.6s ease-in-out ;
  }

  
  .logo.is-animetion {
    span:nth-of-type(1){ animation-delay: 0.0s; }
    span:nth-of-type(2){ animation-delay: 0.1s; }
    span:nth-of-type(3){ animation-delay: 0.2s; }
    span:nth-of-type(4){ animation-delay: 0.3s; }
    span:nth-of-type(5){ animation-delay: 0.4s; }
    span:nth-of-type(6){ animation-delay: 0.5s; }
    span:nth-of-type(7){ animation-delay: 0.6s; }
    span:nth-of-type(8){ animation-delay: 0.7s; }
    span:nth-of-type(9){ animation-delay: 0.8s; }
    span:nth-of-type(10){ animation-delay: 0.9s; }
    span:nth-of-type(11){ animation-delay: 1.0s; }
    span:nth-of-type(12){ animation-delay: 1.1s; }
    span:nth-of-type(13){ animation-delay: 1.2s; }
    span:nth-of-type(14){ animation-delay: 1.3s; }
    span:nth-of-type(15){ animation-delay: 1.4s; }
    span:nth-of-type(16){ animation-delay: 1.5s; }
    span:nth-of-type(17){ animation-delay: 1.6s; }
    span:nth-of-type(18){ animation-delay: 1.7s; }
    span:nth-of-type(19){ animation-delay: 1.8s; }
    span:nth-of-type(20){ animation-delay: 1.9s; }
  }
  
  @keyframes wave-text{
    00%{
      -webkit-transform: translateY(0em);
    }
    60%{
      -webkit-transform: translateY(-0.2em);
    }
    100%{
      -webkit-transform: translateY(0em);
    }
  }
  
  .waving-text span {
    display: inline-block;
    transform-origin: center bottom;
    animation: wave 2s ease-in-out ;
  }
  
  .waving-text span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .waving-text span:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .waving-text span:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .waving-text span:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  .waving-text span:nth-child(5) {
    animation-delay: 0.4s;
  }
  .waving-text span:nth-child(6) {
    animation-delay: 0.5s;
  }
  .waving-text span:nth-child(7) {
    animation-delay: 0.6s;
  }
  
  @keyframes wave {
    0% {
      -webkit-transform: translateY(0);
    }
  
    25% {
      -webkit-transform: translateY(-5px);
    }
  
    50% {
      transform: translateY(0);
    }
  
    75% {
      transform: translateY(5px);
    }
  
    100% {
      transform: translateY(0);
    }
  }


  .back-img-div{
    position: relative;
    z-index: 1;
    bottom: 300px;
    margin-bottom: -150px;

  }


  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


.slider-text{
 height:100%;
}
.slider-text h5{
  padding: 1%;
  font-size: 20px;
}

/* ==================================================================================About Us============================================ */
.about-cards{
  background-color: #ffffff;
  padding: 20px 15px 10px 15px;
  border-radius: 4px;
  color: #87D900;
  box-shadow: 4px 8px 10px 0px #00000026;
}
.about-cards h1 {
  font-size: xx-large;
  padding-bottom: 2%;
}
.about-cards text {
  color: #707070;
  padding-top: 10px;

  
}

.des-img-border {
  border: 3px solid #ffffff;
  border-radius: 5px;
}
.dis-img-bar {
  position: relative;
  padding: 2px;
  width: 22px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 15%;
  left: 25%;
  right: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.rec-dot{
  width: 5px;
  height: 5px;
  background-color: #beb7b7 ;
  margin-top: 20px;
  border-width: 0px;
  box-shadow: none;
 
}

button.rec-dot:focus{
  width: 20px;
  border-radius: 5px ;
  border-width: 0px;
  box-shadow: none;
}

button.rec-dot:active{
  width: 20px;
  border-radius: 5px;
  border-width: 0px;
  box-shadow: none;
}
.rec-dot .active {
  width: 20px;
  border-radius: 5px;
  border-width: 0px;
  box-shadow: none;
}
.rec-dot.hbquNM{
  box-shadow: none;
  width: 20px;
  border-radius: 5px;
  border-width: 0px;
}
.rec.rec-arrow {
  transform: scale(0.75);
}


@media only screen and (max-width: 450px) {
  button.rec-dot:focus{
    width: 20px;
    border-radius: 5px ;
    border-width: 0px;
    box-shadow: none;
    background-color: #beb7b7 ;
  }
  .serv{
    padding: 10px 10px;
  }
  button.rec-dot:active{
    width: 20px;
    border-radius: 5px;
    border-width: 0px;
    box-shadow: none;
    background-color: #beb7b7 ;
  }
  button.rec-dot{
    width: 5px;
    height: 5px;
    background-color: #beb7b7 ;
    margin-top: 4px;
    border-width: 0px; 
  }
  .rec-dot .active {
    width: 20px;
    border-radius: 5px;
    border-width: 0px;
    box-shadow: none;
    background-color: #beb7b7 ;
  }
  .rec-dot.hbquNM{
    box-shadow: none;
    width: 20px;
    border-radius: 5px;
    border-width: 0px;
    background-color: #beb7b7 ;
  }
  .rec.rec-arrow {
    border-radius: 50%;
}
.rec.rec-arrow{
  background-color: #8a8e8366;
}
/* round buttons on hover */
  .rec.rec-arrow:hover {
    border-radius: 50%;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
.rec.rec-arrow-right{
  position: absolute;
  right: 5%;
  background-color: #e1e6dcc6;
  z-index: 10;
  transform: scale(0.5);

}

.rec.rec-arrow-left{
  position: absolute;
  left: 5%;
  background-color: #e1e6dcc6;
  z-index: 10;
  transform: scale(0.5);

}
.tw-mx-6 {
  margin-left: 0;
  margin-right: 0;
}
}


