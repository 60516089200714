

.ft{
    background-image: url('../Images/footerBg.png');
    background-position: center;
    background-repeat: no-repeat;
     background-size: cover;

}

.overlay{
    background-color: #000000CC;
    z-index:-1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}


.contain{
    margin-left: 40px;
    margin-right: 40px;
}
.links li a {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    color:white

}
 a:hover {
    color: #86241D;
}
.socials{
    filter: none;
    transition: filter 0.2s ease;
}
.socials:hover{
    cursor: pointer;
    filter: invert(70%) sepia(35%) saturate(3882%) hue-rotate(339deg) brightness(96%) contrast(96%);
}